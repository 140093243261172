<template>
	<div>
		<div class="tw-mt-3 tw-mb-6">
			<div class="overflow-auto tw-mt-3 tw-max-h-40" style="min-height: 100px;">
				<div class="table-ui-detail">
					<table>
						<thead>
							<tr>
								<th width="20%">
									{{ $t("employee.year") }}
								</th>
								<th width="20%">
									{{ $t("employee.employerName") }}
								</th>
								<th width="20%">
									{{ $t("employee.industry") }}
								</th>
								<th width="20%">
									{{ $t("employee.position") }}
								</th>
								<th width="20%">
									{{
										$t("employee.address")
									}}
								</th>
								<th style="text-align:center; width: 1px;" width="5%">
									<Tooltip placement="top" :transfer="true" :content="$t('add')">
										<a href="#" class="text-primary" @click.prevent="onAddItem()">
											<i class="fas fa-plus"></i> </a></Tooltip>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(employment, index) in items" :key="index">
								<td>
									<Input v-model="employment.year" style="width: 100%"
										:class="{ 'ivu-form-item-error': errors.has('employment_histories.' + index + '.year') }"
										class="tw-text-right">
									</Input>
									<div class="validate text-danger" v-if="errors.has('employment_histories.' + index + '.year')">
										{{ errors.first('employment_histories.' + index + '.year') }}
									</div>
								</td>
								<td>
									<Input v-model="employment.employer_name" style="width: 100%"
										:class="{ 'ivu-form-item-error': errors.has('employment_histories.' + index + '.employer_name') }"
										class="tw-text-right">
									</Input>
									<div class="validate text-danger" v-if="errors.has('employment_histories.' + index + '.employer_name')">
										{{ errors.first('employment_histories.' + index + '.employer_name') }}
									</div>
								</td>
								<td>
									<Input v-model="employment.industry" style="width: 100%" class="tw-text-right">
									</Input>
								</td>
								<td>
									<Input v-model="employment.position" style="width: 100%"
										:class="{ 'ivu-form-item-error': errors.has('employment_histories.' + index + '.position') }"
										class="tw-text-right">
									</Input>
									<div class="validate text-danger" v-if="errors.has('employment_histories.' + index + '.position')">
										{{ errors.first('employment_histories.' + index + ".position") }}
									</div>
								</td>
								<td>
									<Input v-model="employment.address" style="width: 100%" class="tw-text-right">
									</Input>
								</td>
								<td class="text-center">
									<Tooltip placement="top" :transfer="true" :content="$t('remove')">
										<a href="#" class="text-danger" @click.prevent="
											removeRow(employment)
											">
											<Icon type="ios-trash" size="20" />
										</a>
									</Tooltip>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="validate text-danger" v-if="errors.has('employment_histories')">
				{{ errors.first("employment_histories") }}
			</div>
		</div>
	</div>
</template>

<script>
import { Errors } from "form-backend-validation";

export default {
	name: "employment-history",
	props: ["value", "validation"],
	data() {
		return {
			model: {},
			errors: new Errors()
		};
	},
	computed: {
		items() {
			return this.model.employment_histories;
		}
	},
	created() {
		this.model = this.value;
	},
	methods: {
		onAddItem() {
			this.model.employment_histories.push({
				employment_history_id: null,
				year: null,
				employer_name: null,
				industry: null,
				position: null,
				address: null
			});
		},
		removeRow(item) {
			this.model.employment_histories.splice(this.model.employment_histories.indexOf(item), 1);
		},
	},
	watch: {
		validation: {
			handler() {
				this.errors = new Errors(this.validation.errors);
			},
			deep: true
		}
	}
};
</script>

<style lang="css" scoped>
.section-divider-underline {
	padding-bottom: 5px;
	margin: 0 0 8px 0;
	/* border-bottom: 1px solid #eee; */
	font-size: 16px;
	color: #929090;
	font-weight: 400;
	text-transform: uppercase;
}
</style>
