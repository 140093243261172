import { render, staticRenderFns } from "./employment-history.vue?vue&type=template&id=18b67b04&scoped=true&"
import script from "./employment-history.vue?vue&type=script&lang=js&"
export * from "./employment-history.vue?vue&type=script&lang=js&"
import style0 from "./employment-history.vue?vue&type=style&index=0&id=18b67b04&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18b67b04",
  null
  
)

export default component.exports