import { render, staticRenderFns } from "./education-history.vue?vue&type=template&id=5a94958e&scoped=true&"
import script from "./education-history.vue?vue&type=script&lang=js&"
export * from "./education-history.vue?vue&type=script&lang=js&"
import style0 from "./education-history.vue?vue&type=style&index=0&id=5a94958e&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a94958e",
  null
  
)

export default component.exports