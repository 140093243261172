<template>
	<div @click="$emit('click')">
		<div v-if="isImage"
			class="tw-border tw-p-2 tw-rounded-md tw-w-12 tw-flex tw-items-center tw-justify-center tw-cursor-pointer hover:tw-shadow-md">
			<img :src="readFromUrl" alt="" class="tw-h-full tw-rounded">
		</div>
		<div v-if="isPdf"
			class="tw-border tw-p-2 tw-rounded-md tw-w-12 tw-flex tw-items-center tw-justify-center tw-cursor-pointer hover:tw-shadow-md">
			<i class="far fa-file-pdf tw-text-red-600 fa-2x"></i>
		</div>
		<div v-if="isExcel"
			class="tw-border tw-p-2 tw-rounded-md tw-w-12 tw-flex tw-items-center tw-justify-center tw-cursor-pointer hover:tw-shadow-md">
			<i class="far fa-file-excel fa-2x tw-text-green-600"></i>
		</div>
	</div>
</template>

<script>
export default {
	name: 'fileViewer',
	props: {
		resource: {
			type: Object,
			required: true
		}
	},
	computed: {
		readFromUrl() {
			if (this.resource.file instanceof File) {
				return URL.createObjectURL(this.resource.file);
			}
			return this.resource.storage_path
		},
		isImage() {
			return this.resource.type.includes('image')
		},
		isPdf() {
			return this.resource.type.includes('application/pdf')
		},
		isExcel() {
			return this.resource.type.includes('spreadsheetml')
		}
	}
}
</script>