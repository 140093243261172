<template>
    <div :fileResource="fileResource" style="height: 70vh;">
        <img v-if="isImage" :src=readFromUrl alt="" class="tw-w-full tw-h-full tw-object-contain">
        <div v-if="isPdf">
            <iframe :src="pdfFile" frameborder="0" class="tw-w-full tw-h-full" style="height: 70vh;"></iframe>
        </div>
        <div v-else class="tw-text-center" style="height: 70vh; padding-top:280px;">
            <p class="tw-font-semibold tw-text-gray-700">{{ getFileName }}</p>
            <a :href="unknowFile" :download="getFileName">
                <i v-if="!isImage" class="fas fa-cloud-download-alt tw-text-5xl"></i>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'viewModal',
    props: {
        fileResource: {
            type: Object,
            required: true
        }
    },
    computed: {
        readFromUrl() {
            if (this.fileResource.file instanceof File) {
                return URL.createObjectURL(this.fileResource.file);
            }
            return this.fileResource.storage_path
        },
        pdfFile() {
            if (!this.fileResource) {
                return undefined
            }
            return this.fileResource.storage_path
        },
        unknowFile() {
            if (!this.fileResource) {
                return undefined
            }
            return this.fileResource.storage_path
        },
        getFileName() {
            if (!(this.fileResource.type == 'application/pdf')) {
                return this.fileResource.original_file_name
            }
            return undefined
        },
        isImage() {
            if (this.fileResource.type == 'image/jpeg' || this.fileResource.type == 'image/png' || this.fileResource.type == 'image/jpg') {
                return this.fileResource.storage_path
            }
            return undefined
        },
        isPdf() {
            if (this.fileResource.type == 'application/pdf') {
                return this.fileResource.storage_path
            }
            return undefined
        }
    },
}
</script>
