<template>
	<div>
		<div class="tw-mt-3 tw-mb-6">
			<!-- <h1 class="section-divider-underline">{{ $t("employee.documents") }}</h1> -->
			<div class="overflow-auto tw-mt-3 tw-max-h-40" style="min-height: 100px;">
				<div class="table-ui-detail">
					<table>
						<thead>
							<tr>
								<th width="25%">
									{{ $t("employee.year") }}
								</th>
								<th width="25%">
									{{ $t("employee.degree") }}
								</th>
								<th width="25%">
									{{ $t("employee.institutionName") }}
								</th>
								<th width="25%">
									{{ $t("employee.address") }}
								</th>
								<th style="text-align:center; width: 1px;" width="5%">
									<Tooltip placement="top" :transfer="true" :content="$t('add')">
										<a href="#" class="text-primary" @click.prevent="onAddItem()">
											<i class="fas fa-plus"></i> </a></Tooltip>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(education, index) in items" :key="index">
								<td>
									<Input v-model="education.year" style="width: 100%"
										:class="{ 'ivu-form-item-error': errors.has('education_histories.' + index + '.year') }"
										class="tw-text-right">
									</Input>
									<div class="validate text-danger" v-if="errors.has('education_histories.' + index + '.year')">
										{{ errors.first('education_histories.' + index + '.year') }}
									</div>
								</td>
								<td>
									<Input v-model="education.degree" style="width: 100%" class="tw-text-right">
									</Input>
								</td>
								<td>
									<Input v-model="education.institution_name" style="width: 100%"
										:class="{ 'ivu-form-item-error': errors.has('education_histories.' + index + '.institution_name') }"
										class="tw-text-right">
									</Input>
									<div class="validate text-danger"
										v-if="errors.has('education_histories.' + index + '.institution_name')">
										{{ errors.first('education_histories.' + index + ".institution_name") }}
									</div>
								</td>
								<td>
									<Input v-model="education.address" style="width: 100%" class="tw-text-right">
									</Input>
								</td>
								<td class="text-center">
									<Tooltip placement="top" :transfer="true" :content="$t('remove')">
										<a href="#" class="text-danger" @click.prevent="
											removeRow(education)
											">
											<Icon type="ios-trash" size="20" />
										</a>
									</Tooltip>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="validate text-danger" v-if="errors.has('educationHistories')">
				{{ errors.first("educationHistories") }}
			</div>
		</div>
	</div>
</template>

<script>
import { Errors } from "form-backend-validation";

export default {
	name: "education-history",
	props: ["value", "validation"],
	data() {
		return {
			model: {},
			errors: new Errors()
		};
	},
	computed: {
		items() {
			return this.model.education_histories;
		}
	},
	created() {
		this.model = this.value;
	},
	methods: {
		onAddItem() {
			this.model.education_histories.push({
				education_history_id: null,
				year: null,
				degree: null,
				institution_name: null,
				address: null
			});
		},
		removeRow(item) {
			this.model.education_histories.splice(this.model.education_histories.indexOf(item), 1);
		},
	},
	watch: {
		validation: {
			handler() {
				this.errors = new Errors(this.validation.errors);
			},
			deep: true
		}
	}
};
</script>

<style lang="css" scoped>
.section-divider-underline {
	padding-bottom: 5px;
	margin: 0 0 8px 0;
	/* border-bottom: 1px solid #eee; */
	font-size: 16px;
	color: #929090;
	font-weight: 400;
	text-transform: uppercase;
}
</style>
