<template>
    <div>
        <h1 class="section-divider-underline">{{ $t('employee.payroll') }}</h1>
        <div class="row">
            <div class="col-md-6">
                <div class="row mb-3">
                    <label
                        class="form-col-label control-label col-md-5"
                    ></label>
                    <div class="col-md-7">
                        <ts-checkbox
                            v-model="model.is_tax_responsed_by_company"
                            >{{
                                $t('employee.isTaxResponsedByCompany')
                            }}</ts-checkbox
                        >
                    </div>
                </div>
                <div class="row mb-3">
                    <label
                        class="form-col-label control-label col-md-5"
                    ></label>
                    <div class="col-md-7">
                        <ts-checkbox v-model="model.is_spouse_dependant">{{
                            $t('employee.isSpouseDependant')
                        }}</ts-checkbox>
                    </div>
                </div>
                <div class="row">
                    <label class="form-col-label control-label col-md-5">{{
                        $t('employee.chilDependant')
                    }}</label>
                    <div class="col-md-7">
                        <input
                            type="number"
                            v-model.number="model.num_child_dependant"
                            class="form-control"
                            :class="{
                                'is-invalid': errors.has('num_child_dependant')
                            }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('num_child_dependant')"
                        >
                            {{ errors.first('num_child_dependant') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row mb-3">
                    <label class="form-col-label control-label col-md-5">
                        {{ $t('employee.payrollCycleDate') }}
                    </label>
                    <div class="col-md-7">
                        <select
                            v-model="model.pc_date_id"
                            class="form-control"
                            :class="{ 'is-invalid': errors.has('pc_date_id') }"
                        >
                            <option
                                :value="null"
                                disabled
                                selected
                                style="display: none;"
                                >{{ $t('select') }}</option
                            >
                            <option
                                v-for="(pcDate, index) in pcDates"
                                :key="index"
                                :value="pcDate.pc_date_id"
                                >{{ pcDate.pc_date_name }}</option
                            >
                        </select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('pc_date_id')"
                        >
                            {{ errors.first('pc_date_id') }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-col-label control-label col-md-5">
                        {{ $t('employee.paymentType') }}
                    </label>
                    <div class="col-md-7">
                        <select
                            v-model="model.payment_type_id"
                            class="form-control"
                            :class="{
                                'is-invalid': errors.has('payment_type_id')
                            }"
                        >
                            <option
                                :value="null"
                                disabled
                                selected
                                style="display: none;"
                                >{{ $t('select') }}</option
                            >
                            <option
                                v-for="(paymentType, index) in paymentTypes"
                                :key="index"
                                :value="paymentType.payment_type_id"
                                >{{ paymentType.payment_type }}</option
                            >
                        </select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('payment_type_id')"
                        >
                            {{ errors.first('payment_type_id') }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="form-col-label control-label required col-md-5">
                        {{ $t('employee.numberOfPayroll') }}
                    </label>
                    <div class="col-md-7">
                        <select
                            v-model="model.num_of_payroll"
                            class="form-control"
                            :class="{
                                'is-invalid': errors.has('num_of_payroll')
                            }"
                        >
                            <option :value="null">{{ $t('select') }}</option>
                            <option
                                v-for="(p, index) in numOfPayrolls"
                                :key="index"
                                :value="p.value"
                                >{{ p.text }}</option
                            >
                        </select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('num_of_payroll')"
                        >
                            {{ errors.first('num_of_payroll') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { mapState } from 'vuex'

export default {
    name: 'payroll',
    props: ['value', 'validation'],
    data () {
        return {
            model: {},
            errors: new Errors(),
            numOfPayrolls: [
                {
                    value: 1,
                    text: 'One Time'
                },
                {
                    value: 2,
                    text: 'Two Times'
                }
            ]
        }
    },
    created () {
        this.model = this.value
    },
    computed: {
        ...mapState('humanResource/employee', ['pcDates', 'paymentTypes'])
    },
    watch: {
        validation: {
            handler () {
                this.errors = new Errors(this.validation.errors)
            },
            deep: true
        }
    }
}
</script>
<style lang="css" scoped>
.section-divider-underline {
    padding-bottom: 5px;
    margin: 0 0 8px 0;
    /* border-bottom: 1px solid #eee; */
    font-size: 16px;
    color: #929090;
    font-weight: 400;
    text-transform: uppercase;
}
</style>
