<template>
    <div>
        <h1 class="section-divider-underline">{{ $t("employee.detail") }}</h1>
        <div class="tw-flex">
            <div class="col-md-6">
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5 required">
                        {{ $t("employee.branch") }}
                    </label>
                    <div class="col-md-7">
                        <Select v-model="model.branch_id" placeholder="Branch" :filterable="true" :filter-by-label="true">
                            <Option v-for="item in branchList" :value="item.branch_id" :key="item.branch_id"
                                :label="item.branch_name_en">
                                {{ item.branch_name_en }}
                            </Option>
                        </Select>
                        <div class="invalid-feedback" v-if="errors.has('branch_id')">
                            {{ errors.first("branch_id") }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5 required">
                        {{ $t("employee.status") }}
                    </label>
                    <div class="col-md-7">
                        <select v-model.number="model.status_id" class="form-control"
                            :class="{ 'is-invalid': errors.has('status_id') }" @change="clearOption">
                            <option :value="null" disabled selected style="display: none;">{{ $t("select") }}</option>
                            <option v-for="(st, index) in status" :key="index" :value="st.status_id">
                                {{ st.status }}
                            </option>
                        </select>
                        <div class="invalid-feedback" v-if="errors.has('status_id')">
                            {{ errors.first("status_id") }}
                        </div>
                    </div>
                </div>

                <div class="row mb-3" v-if="model.status_id == 2 || model.status_id == 3">
                    <label class="form-label col-form-label col-md-5" :class="{
                        required:
                            model.status_id == 2 || model.status_id == 3
                    }">
                        {{ $t("employee.statusEffectiveDate") }}
                    </label>
                    <div class="col-md-7">
                        <DatePicker :value="model.status_effective_date" style="width:100%" placeholder="DD-MM-YYYY"
                            format="dd-MM-yyyy" @on-change="onChangeEffectiveDate"></DatePicker>
                        <div class="text-danger" v-if="errors.has('status_effective_date')">
                            {{ errors.first("status_effective_date") }}
                        </div>
                    </div>
                </div>

                <div class="row mb-3" v-if="model.status_id == 2 || model.status_id == 3">
                    <label class="form-label col-form-label col-md-5" :class="{
                        required:
                            model.status_id == 2 || model.status_id == 3
                    }">
                        {{ $t("employee.statusReason") }}
                    </label>
                    <div class="col-md-7">
                        <select v-model.number="model.status_reason_id" class="form-control" :class="{
                            'is-invalid': errors.has('status_reason_id')
                        }">
                            <option :value="null" disabled selected style="display: none;">{{ $t("select") }}</option>
                            <option v-for="(st, index) in statusReasons" :key="index" :value="st.status_reason_id">
                                {{ st.reason }}
                            </option>
                        </select>
                        <div class="text-danger" v-if="errors.has('status_reason_id')">
                            {{ errors.first("status_reason_id") }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5">{{
                        $t("employee.province")
                    }}</label>
                    <div class="col-md-7">
                        <Select v-model="model.province_code" placeholder="Province" :filterable="true"
                            :filter-by-label="true" @on-change="onProvinceChange">
                            <Option v-for="item in provinceList" :value="item.value" :key="item.value"
                                :label="item.label">
                                {{ item.label }}
                            </Option>
                        </Select>
                        <div class="validate ts-text-xs" v-if="errors.has('province_code')">
                            {{ errors.first("province_code") }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5">{{
                        $t("employee.district")
                    }}</label>
                    <div class="col-md-7">
                        <ts-loading-banner :loading="district_loading">
                            <Select v-model="model.district_code" placeholder="District" :filterable="true"
                                :filter-by-label="true" @on-change="onDistrictChange">
                                <Option v-for="item in districts" :value="item.value" :key="item.value"
                                    :label="item.label">
                                    {{ item.label }}
                                </Option>
                            </Select>
                        </ts-loading-banner>
                        <div class="validate ts-text-xs" v-if="errors.has('district_code')">
                            {{ errors.first("district_code") }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5">{{
                        $t("employee.province")
                    }}</label>
                    <div class="col-md-7">
                        <ts-loading-banner :loading="commune_loading">
                            <Select v-model="model.commune_code" placeholder="Commune" :filterable="true"
                                :filter-by-label="true" @on-change="onCommuneChange">
                                <Option v-for="item in communes" :value="item.value" :key="item.value"
                                    :label="item.label">
                                    {{ item.label }}
                                </Option>
                            </Select>
                        </ts-loading-banner>
                        <div class="validate ts-text-xs" v-if="errors.has('commune_code')">
                            {{ errors.first("commune_code") }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5">{{
                        $t("employee.province")
                    }}</label>
                    <div class="col-md-7">
                        <ts-loading-banner :loading="village_loading">
                            <Select v-model="model.village_code" placeholder="Village" :filterable="true"
                                :filter-by-label="true">
                                <Option v-for="item in villages" :value="item.value" :key="item.value"
                                    :label="item.label">
                                    {{ item.label }}
                                </Option>
                            </Select>
                        </ts-loading-banner>
                        <div class="validate ts-text-xs" v-if="errors.has('village_code')">
                            {{ errors.first("village_code") }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5">{{
                        $t("employee.phone")
                    }}</label>
                    <div class="col-md-7">
                        <input type="text" v-model="model.phone" class="form-control"
                            :class="{ 'is-invalid': errors.has('phone') }" />
                        <div class="invalid-feedback" v-if="errors.has('phone')">
                            {{ errors.first("phone") }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5">{{
                        $t("employee.email")
                    }}</label>
                    <div class="col-md-7">
                        <input type="text" v-model="model.email" class="form-control"
                            :class="{ 'is-invalid': errors.has('email') }" />
                        <div class="invalid-feedback" v-if="errors.has('email')">
                            {{ errors.first("email") }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5">{{
                        $t("employee.workEmail")
                    }}</label>
                    <div class="col-md-7">
                        <input type="text" v-model="model.work_email" class="form-control"
                            :class="{ 'is-invalid': errors.has('work_email') }" />
                        <div class="invalid-feedback" v-if="errors.has('work_email')">
                            {{ errors.first("work_email") }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5 required">{{ $t("employee.hiredDate") }}</label>
                    <div class="col-md-7">
                        <DatePicker :value="model.hire_date" style="width:100%" placeholder="DD-MM-YYYY"
                            format="dd-MM-yyyy" @on-change="onChangeHireDate"></DatePicker>
                        <div class="text-danger" v-if="errors.has('hire_date')">
                            {{ errors.first("hire_date") }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5 required">
                        {{ $t("employee.probationEndDate") }}
                    </label>
                    <div class="col-md-7">
                        <DatePicker :value="model.probation_end_date" disabled style="width:100%"
                            placeholder="DD-MM-YYYY" format="dd-MM-yyyy"></DatePicker>
                        <div class="text-danger" v-if="errors.has('probation_end_date')">
                            {{ errors.first("probation_end_date") }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5 required">{{ $t("employee.employeeType") }}</label>
                    <div class="col-md-7">
                        <select v-model.number="model.emp_type_id" class="form-control"
                            :class="{ 'is-invalid': errors.has('emp_type_id') }">
                            <option :value="null" disabled selected style="display: none;">{{ $t("select") }}</option>
                            <option value="1">បុគ្គលិកក្នុងស្រុក | Local</option>
                            <option value="2">ជនបរទេស | Expat</option>
                            <option value="3">បុគ្គលិកឯករាជ្យ | Freelancer</option>
                        </select>
                        <div class="invalid-feedback" v-if="errors.has('emp_type_id')">
                            {{ errors.first("emp_type_id") }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5">
                        {{ $t("employee.position") }}
                    </label>
                    <div class="col-md-7">
                        <select v-model.number="model.position_id" class="form-control"
                            :class="{ 'is-invalid': errors.has('position_id') }">
                            <option :value="null" disabled selected style="display: none;">{{ $t("select") }}</option>
                            <option v-for="(position, index) in positions" :key="index" :value="position.position_id">{{
                                position.position_name_en }}</option>
                        </select>
                        <div class="invalid-feedback" v-if="errors.has('position_id')">
                            {{ errors.first("position_id") }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5 required">
                        {{ $t("employee.shift") }}
                    </label>
                    <div class="col-md-7">
                        <select v-model.number="model.shift_id" class="form-control"
                            :class="{ 'is-invalid': errors.has('shift_id') }">
                            <option :value="null" disabled selected style="display: none;">{{ $t("select") }}</option>
                            <option v-for="(shift, index) in shifts" :key="index" :value="shift.shift_id">{{
                                shift.shift_name }} ({{
        shift.shift_type
    }})</option>
                        </select>
                        <div class="invalid-feedback" v-if="errors.has('shift_id')">
                            {{ errors.first("shift_id") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import { isEmpty } from "lodash";

// iview
//import 'view-design/dist/styles/iview.css'
import Vue from "vue";
import ViewUI from "view-design";
import locale from "view-design/dist/locale/en-US";
Vue.use(ViewUI, { locale });
Vue.use(ViewUI);

export default {
    name: "detail",
    props: ["value", "validation"],
    data() {
        return {
            model: {},
            errors: new Errors(),
            options: null,
            branchList: [],
            districts: [],
            communes: [],
            villages: [],
            village_loading: false,
            commune_loading: false,
            district_loading: false
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapState("humanResource/employee", [
            "status",
            "statusReason",
            "positions",
            "shifts",
            "nationalities",
            "edit_data"
        ]),
        statusReasons() {
            if (this.model.status_id) {
                return this.statusReason.filter(
                    s => s.status_id == this.model.status_id
                );
            }
            return this.statusReason;
        },
        provinceList() {
            return this.$store.state.humanResource.employee.provinces.map(
                el => ({
                    value: el.province_code,
                    label: el.name_en + ' | ' + el.name_kh
                })
            );
        }
    },
    methods: {
        ...mapActions("humanResource/employee", [
            "getDistrict",
            "getCommune",
            "getVillage"
        ]),
        onChangeHireDate(date) {
            this.model.hire_date = date;
            this.model.probation_end_date = moment(
                this.model.hire_date,
                "DD-MM-YYYY"
            )
                .add(3, "M")
                .format("DD-MM-YYYY");
        },
        onChangeEffectiveDate(date) {
            this.model.status_effective_date = date;
        },
        clearOption() {
            this.model.status_reason_id = null;
            this.options = null;
        },
        async fetchBranch() {
            this.$emit("loading", true);
            await this.$store
                .dispatch("auth/user/getBranchAssigned")
                .then(response => {
                    this.branchList = response.data;
                })
                .catch(error => {
                    this.$toasted.error(error.message);
                })
                .finally(() => {
                    this.$emit("loading", true);
                });

            await this.$store
                .dispatch("humanResource/employee/getProvince")
                .finally(() => {
                    this.setEditData();
                    this.loading = false;
                });
        },
        async onProvinceChange(value) {
            this.model.disctrict_code = null;
            this.model.commune_code = null;
            this.model.village_code = null;
            this.district_loading = true;
            await this.getDistrict(value)
                .then(response => {
                    this.districts = response.data.map(el => ({
                        value: el.district_code,
                        label: el.name_en + ' | ' + el.name_kh
                    }));
                })
                .finally(() => {
                    this.district_loading = false;
                });
        },
        async onDistrictChange(value) {
            this.model.commune_code = null;
            this.model.village_code = null;
            this.commune_loading = true;
            await this.getCommune(value)
                .then(response => {
                    this.communes = response.data.map(el => ({
                        value: el.commune_code,
                        label: el.name_en + ' | ' + el.name_kh
                    }));
                })
                .finally(() => {
                    this.commune_loading = false;
                });
        },
        async onCommuneChange(value) {
            this.model.village_code = null;
            this.village_loading = true;
            await this.getVillage(value)
                .then(response => {
                    this.villages = response.data.map(el => ({
                        value: el.village_code,
                        label: el.name_en + ' | ' + el.name_kh
                    }));
                })
                .finally(() => {
                    this.village_loading = false;
                });
        },
        setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.province_code = this.edit_data.province_code;
                if (this.edit_data.province_code) {
                    this.getDistrict(this.edit_data.province_code).then(
                        response => {
                            this.districts = response.data.map(el => ({
                                value: el.district_code,
                                label: el.name_en + ' | ' + el.name_kh
                            }));
                            this.model.district_code = this.edit_data.district_code;
                        }
                    );
                }
                if (this.edit_data.district_code) {
                    this.getCommune(this.edit_data.district_code).then(
                        response => {
                            this.communes = response.data.map(el => ({
                                value: el.commune_code,
                                label: el.name_en + ' | ' + el.name_kh
                            }));
                            this.model.commune_code = this.edit_data.commune_code;
                        }
                    );
                }
                if (this.edit_data.commune_code) {
                    this.getVillage(this.edit_data.commune_code).then(
                        response => {
                            this.villages = response.data.map(el => ({
                                value: el.village_code,
                                label: el.name_en + ' | ' + el.name_kh
                            }));
                            this.model.village_code = this.edit_data.village_code;
                        }
                    );
                }
            }
        }
    },
    watch: {
        "model.status_id": function (value) {
            if ((value == 2) | (value == 3)) {
                this.model.status_effective_date = !isEmpty(this.edit_data)
                    ? this.edit_data.status_effective_date
                    : moment().format("DD-MM-YYYY");

                this.model.status_reason_id = !isEmpty(this.edit_data)
                    ? this.edit_data.status_reason_id
                    : null;
            } else {
                this.model.status_effective_date = "";
            }
        },
        validation: {
            handler() {
                this.errors = new Errors(this.validation.errors);
            },
            deep: true
        }
    }
};
</script>

<style lang="css" scoped>
.section-divider-underline {
    padding-bottom: 5px;
    margin: 0 0 8px 0;
    /* border-bottom: 1px solid #eee; */
    font-size: 16px;
    color: #929090;
    font-weight: 400;
    text-transform: uppercase;
}
</style>
